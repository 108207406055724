import React, { useState, useRef, useEffect, lazy, Suspense } from 'react'
import {
  useLocation,
  createSearchParams,
  useSearchParams,
  useParams,
  useNavigate
} from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Box from '@material-ui/core/Box'
import Grid from '@mui/material/Grid'
import { useStyles } from './styles'
import Avatar from '@mui/material/Avatar'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import _ from 'lodash'
import { checkExpiry, getSignedUrl } from '../../utils/AWS'
import ImageIcon from '@mui/icons-material/Image'
import Badge from '@mui/material/Badge'
import Tooltip from '@mui/material/Tooltip'
import { resumeStyle } from './resumeStyle'
import {
  getInitialsFromUserName,
  checkArrOfObjHasAtleastOneValue
} from '../../utils/String'
import {
  Button,
  ImageRender,
  useConfirmation,
  ImageAvatarFallback,
  TextLineLimiter,
  ImageGallery,
  Loader
} from '../../components'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import { checkUserRoleSuperUser, checkUserRoleViewer } from '../../utils/User'
import { ROUTES } from '../../config/routes'
import {
  updatePeople,
  deletePeople,
  deletePeopleMapping,
  updatePeopleTag,
  updatePeopleName,
  fetchPeople
} from '../../store/People/Actions'
import { useDispatch, useSelector } from 'react-redux'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useColor } from '../../ThemeContext'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import {
  saveMasterPeopleNotes,
  updateResource,
  changeMasterPeopleName
} from '../../store/api'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import StyleIcon from '@mui/icons-material/Style'
import Drawer from '@mui/material/Drawer'
import TagCenterUpdate from '../TagCenterUpdate'
import moment from 'moment'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Menu,
  Fade
} from '@mui/material'
import { toast } from 'react-toastify'

import TextField from '@mui/material/TextField'
import { initalizeDownload } from '../../utils/DownloadFromS3/DownloadFromS3'
import DownloadIcon from '@mui/icons-material/Download'
import ReactResizeDetector from 'react-resize-detector'
import {
  moveResume,
  updateParsedResumeData,
  updatePeopleInfo,
  updatePeopleNotes
} from '../../store/People/Actions/UpdatePeople/UpdatePeople'
import ReactContentEditable from '../../components/ReactContentEditable'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import DisplayTags from './DisplayTags'
import {
  CircleLoader,
  DocumentIcon,
  MaximizeIcon,
  ThreeDotsIcon
} from '../../components/Icons/Icons'
import { PDFDocument } from 'pdf-lib'
import EditNote from '@mui/icons-material/EditNote'
import Button2 from '../../components/Button/Button2'
import Notebook from '../Notebook/Notebook'
import SearchNew from '../ListFilter/SearchNew'
import MoveModal from './MoveModal'
import ResumeGrid from './ResumeGrid'

const ResumeViewer = lazy(() => import('./ResumeViewer'))

const ResumeResult = (props) => {
  const {
    people = [],
    displayMode,
    s3Obj,
    setResumeOpen = () => {},
    setIsResumeView = () => {},
    isMerge = false,
    selectedPeople = [],
    setSelectedPeople = () => {},
    loadDelay = 0,
    notesList = [],
    setNotesList = () => {},
    tagState = {},
    currentMasterPeopleIndex = '',
    currentResumeIndex = '',
    isIndividualResumeView = false,
    setIsIndividualResume = () => {},
    showAllPeopleDefault = true,
    viewAllPeople = () => {},
    handlePersonSelect = () => {},
    pageType = 'people',
    refresh = () => {}
  } = props

  const [showMoveModal, setShowMoveModal] = useState(false)
  const [moving, setMoving] = useState(false)
  const [selectedResumeOption, setSelectedResumeOption] = useState(null)

  const { masterResumeId = '', resumeId = '' } = useParams()
  const [selectedPerson, setSelectedPerson] = useState(null)
  const [selectedResume, setSelectedResume] = useState(0)
  const [editMode, setEditMode] = useState(false)
  const [showResumeEditor, setShowResumeEditor] = useState(false)
  const [selectedScreenshots, setSelectedScreenshots] = useState([])
  const [resumeLoading, setResumeLoading] = useState(true)
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { domain_id = '' } = domain || {}

  useEffect(() => {
    fetchResumes()
  }, [])

  const fetchResumes = (openResume = false) => {
    if (pageType !== 'dashboard') {
      setResumeLoading(true)
      const req = {
        keyword: '',
        page_num: 1,
        page_size: 10,
        tags: [],
        tag_dates: [],
        master_people_id: masterResumeId,
        isSingleResume: true
      }

      const callback = (payload) => {
        const { peopleList } = payload

        setResumeLoading(false)
        if (peopleList.length > 0) {
          console.log('peopleList 2', peopleList)
          setSelectedPerson(peopleList[0])
          setEditedNote(peopleList[0]?.notes ?? '')
          if (openResume) {
            handleOpenResume(0)
          }
        }
      }

      dispatch(fetchPeople(req, {}, callback))
    }

    if (pageType === 'result' || pageType === 'dashboard') {
      setSelectedPerson(people[0])
      setResumeLoading(false)
    }
  }

  const isSuperUser = checkUserRoleSuperUser()

  const location = useLocation()
  const pathname = location.pathname

  const classes = useStyles()
  const [selectedPerson2, setSelectedPerson2] = useState(1)

  const [showCoverImage, setShowCoverImage] = useState(false)
  const [showAllPeople, setShowAllPeople] = useState(showAllPeopleDefault)
  const [visibleItemCount, setVisibleItemCount] = useState(1)
  const [isSaving, setIsSaving] = useState(false)
  const dispatch = useDispatch()
  const [storedScrollPositionList, setStoredScrollPositionList] = useState(0)
  const [storedScrollPosition, setStoredScrollPosition] = useState(0)
  const [delayedLoading, setDelayedLoading] = useState(false)
  const [showTagDrawer, setShowTagDrawer] = useState(false)

  const [editNotes, setEditNotes] = useState(false)
  const [editedNote, setEditedNote] = useState('')
  const [updatingNotes, setUpdatingNotes] = useState(false)

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [selectedMasterDeleteValue, setSelectedMasterDeleteValue] =
    useState('option1')

  const [hovered, setHovered] = useState(false)
  const [editingNameId, setEditingNameId] = useState('')
  const [editedName, setEditedName] = useState('')

  const navigate = useNavigate()
  const { LIBRARY_PEOPLE_RESUME } = ROUTES

  const { ConfirmDialog, showConfirmDialog } = useConfirmation()

  const isUserViewer = checkUserRoleViewer()

  const [searchParams] = useSearchParams()
  const contentIdParams = searchParams.get('people')

  useEffect(() => {
    setShowAllPeople(showAllPeopleDefault)
  }, [showAllPeopleDefault])

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleSaveNotes(editedNote)
    }, 400)

    return () => {
      clearTimeout(timeout)
    }
  }, [editedNote])

  const handleSaveNotes = async (value) => {
    const userID = selectedPerson?.id
    if (!userID) return
    setUpdatingNotes(true)
    const data = {
      id: userID,
      notes: value
    }

    dispatch(
      updatePeopleNotes(data, () => {
        setUpdatingNotes(false)
      })
    )
  }

  useEffect(() => {
    setTimeout(() => {
      setDelayedLoading(true)
    }, loadDelay)
  }, [loadDelay])

  useEffect(() => {
    if (contentIdParams && pageType === 'dashboard') {
      const index = people.findIndex(
        (person) => person.master_group_id === contentIdParams
      )
      let person = null
      if (index !== -1) {
        person = people[index]
      }
      handlePersonSelect(person)
    }
  }, [location])

  const handleOpenPerson = (index) => {
    const person = people[index]

    if (pageType === 'dashboard') {
      trackEvent(
        mixpanelEvents.DASHBOARD_PEOPLE_RESUME_OPENED,
        'SUCCESS',
        {},
        { people_name: people?.[index]?.name }
      )
      setStoredScrollPosition(window.scrollY)
      handlePersonSelect(person)
      // const resumes = [person, ...person.variations]
      // handlePersonSelect({
      //   resumes,
      //   master_group_id: person.master_group_id
      // })
    } else {
      trackEvent(
        mixpanelEvents.PEOPLE_MASTER_RESUME_OPENED,
        'SUCCESS',
        {},
        { people_name: people?.[index]?.name }
      )
      const scrollableDiv = document.getElementById('scrollableDiv')
      setStoredScrollPosition(scrollableDiv.scrollTop)
      setResumeOpen(true)
      const { id } = person
      setSelectedPerson(index)
      setSelectedPerson2(person)

      setEditedNote(person?.notes ?? '')
      navigate(
        `${LIBRARY_PEOPLE_RESUME}/${person?.id}/${person?.resumes[0]?.id}`
      )
    }
  }

  const handleOpenResume = (index, showCoverImage = true) => {
    trackEvent(
      mixpanelEvents.PEOPLE_RESUME_OPENED,
      'SUCCESS',
      {},
      { variation_index: index }
    )
    if (pageType === 'dashboard') {
      setStoredScrollPositionList(window.scrollY)
      setSelectedResume(index)
    } else {
      const scrollableDiv = document.getElementById('scrollableDiv')
      setStoredScrollPositionList(scrollableDiv?.scrollTop || 0)
      setSelectedResume(index)
      setShowCoverImage(showCoverImage)
      if (pageType === 'people') {
        navigate(
          `${LIBRARY_PEOPLE_RESUME}/${selectedPerson?.id}/${selectedPerson?.resumes[index]?.id}`
        )
      }
    }
  }

  const maintainScrollPosition = (isSelected, positionToScrollTo, type) => {
    const handlePeopleScroll = () => {
      const scrollableDiv = document.getElementById('scrollableDiv')
      if (scrollableDiv) {
        scrollableDiv.scrollTop = isSelected ? 0 : positionToScrollTo
      }
    }

    const handleOtherScroll = () => {
      window.scrollTo({
        top: positionToScrollTo,
        behavior: 'instant'
      })
    }

    if (isSelected) {
      if (pageType === 'people') {
        handlePeopleScroll()
      } else if (type === 'resume') {
        handleOtherScroll()
      }
    } else {
      if (pageType === 'people') {
        handlePeopleScroll()
      } else {
        handleOtherScroll()
      }
    }
  }

  useEffect(() => {
    maintainScrollPosition(
      selectedResume !== null,
      storedScrollPositionList,
      'resume'
    )
  }, [selectedResume])

  useEffect(() => {
    maintainScrollPosition(
      selectedPerson !== null,
      storedScrollPosition,
      'person'
    )
  }, [selectedPerson])

  const handleBackToPerson = () => {
    if (pageType === 'dashboard') {
      setSelectedResume(null)
    } else {
      setSelectedResume(null)
      const url = window.location.href
      const uuidv4Regex =
        /[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i
      const match = url.match(new RegExp(uuidv4Regex.source))
      let firstUuid = ''
      if (match && match[0]) {
        firstUuid = match[0]
      }
      navigate(`${LIBRARY_PEOPLE_RESUME}/${firstUuid}`)
    }
  }

  const handleBacktoSearch = () => {
    setSelectedPerson(null)
    setSelectedResume(null)
    setResumeOpen(false)
    navigate(`${LIBRARY_PEOPLE_RESUME}`)
  }

  const openImage = async (payload = []) => {
    console.log('payload', payload)
    const url = _.cloneDeep(payload)
    if (_.isArray(url)) {
      await Promise.all(
        url.map(async (img, index) => {
          const signedSrc = await checkExpiry(img.src, s3Obj)
          url[index].src = signedSrc
        })
      )
      setSelectedScreenshots(url)
    } else {
      const signedSrc = await checkExpiry(url, s3Obj)
      setSelectedScreenshots([{ src: signedSrc }])
    }

    trackEvent(
      mixpanelEvents.PEOPLE_RESUME_SCREENSHOT_VIEWED,
      'SUCCESS',
      {},
      { proposal_id: selectedPerson?.resumes[selectedResume]?.proposal_id }
    )
  }

  const downloadImage = async (payload = [], resume) => {
    handleDownloadCustomPdf()

    let { proposal_name } = resume
    proposal_name = proposal_name
      ? proposal_name.split('.').slice(0, -1).join('.')
      : 'image'
    if (_.isArray(payload)) {
      payload.forEach(async (img, index) => {
        const fileName = `${proposal_name}_${index + 1}.png`
        initalizeDownload(img, fileName, s3Obj)
      })
    } else {
      const fileName = `${proposal_name}.png`
      initalizeDownload(payload, fileName, s3Obj)
    }
  }

  const handleDownloadCustomPdf = async (proposalId, pageNumber) => {
    // setIsDownloading(true)

    const toastId = toast.loading('Downloading Resume PDF...')

    const pdfUrl = `https://${process.env.REACT_APP_PDF_BUCKET}.s3.amazonaws.com/raw-pdfs/${domain_id}/${proposalId}.pdf`

    const finalUrl = await getSignedUrl(pdfUrl, s3Obj, `${proposalId}.pdf`)
    const arrayBuffer = await fetch(finalUrl).then((res) => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(arrayBuffer)
    const newPdf = await PDFDocument.create()

    const pagesToBeCopied = [pageNumber]
    // for (const [idx, page] of selectedPages.entries()) {
    //   if (page) {
    //     pagesToBeCopied.push(idx)
    //   }
    // }
    const copiedPages = await newPdf.copyPages(pdfDoc, pagesToBeCopied)

    for (const copiedPage of copiedPages) {
      newPdf.addPage(copiedPage)
    }

    const pdfName = selectedPerson?.name

    const pdfBytes = await newPdf.save()
    const blob = new Blob([pdfBytes], { type: 'application/pdf' })

    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = pdfName
    link.click()

    trackEvent(mixpanelEvents.PEOPLE_RESUME_DOWNLOAD, 'SUCCESS', {
      proposal_id: proposalId
    })

    toast.dismiss(toastId)
    // setIsDownloading(false)
  }

  const openPdf = async (proposal_id, page_number) => {
    page_number = page_number || 0
    trackEvent(
      mixpanelEvents.PEOPLE_RESUME_PROPOSAL_VIEWED,
      'NAVIGATION',
      {
        proposal_id,
        page_number
      },
      {}
    )
    const path = `/view/pdf/${proposal_id}/${page_number}`
    if (path) {
      window.open(path, '_blank')
    }
  }

  const handleSetSelectedPeople = (id, isAdd) => {
    if (isAdd) {
      setSelectedPeople([...selectedPeople, id])
    } else {
      setSelectedPeople(selectedPeople.filter((item) => item !== id))
    }
  }

  const { selectedColor } = useColor()

  const renderTagDrawer = () => {
    const id = selectedPerson?.id
    const document_type = 'masterresume'
    return (
      selectedPerson !== null && (
        <Drawer
          anchor={'right'}
          open={showTagDrawer}
          onClose={() => setShowTagDrawer(false)}
        >
          <Box sx={{ padding: '20px', width: '400px' }}>
            <TagCenterUpdate
              id={id}
              filterTags="people"
              document_type={document_type}
              onUpdateCallback={onUpdateCallback}
              cancelCallback={() => setShowTagDrawer(false)}
            />
          </Box>
        </Drawer>
      )
    )
  }

  const onUpdateCallback = (id, mode, tagsFormated) => {
    if (mode === 'tags') {
      const document_type = 'masterresume'
      const tags = []
      tagsFormated.forEach((tag) => {
        tags.push(tag.id)
      })
      let payload = {
        type: document_type
      }
      if (document_type === 'resume') {
        payload = {
          ...payload,
          resume_id: id,
          id: people[selectedPerson]?.id,
          tags
        }
      } else {
        payload = {
          ...payload,
          id: [id],
          tags
        }
      }
      if (isIndividualResumeView) {
        if (document_type === 'resume') {
          const resumes = isIndividualResumeView[0]?.resumes || []
          resumes[selectedResume].tags = tags
          setIsIndividualResume([
            {
              ...isIndividualResumeView[0],
              resumes
            }
          ])
        } else {
          setIsIndividualResume([
            {
              ...isIndividualResumeView[0],
              tags
            }
          ])
        }
      }

      dispatch(
        updatePeopleTag(payload, () => {
          fetchResumes()
        })
      )
    }
  }

  const isMerging = status === 'merging'
  const disabled = isMerging || isSaving

  const handleOpenInEditor = (isMaster = false) => {
    const selectedPeopleData = selectedPerson
    const { resumes = [] } = selectedPeopleData
    const selectedResumeData = isMaster ? resumes[0] : resumes[selectedResume]
    const { proposal_id, id } = selectedResumeData || {}
    const path = `/view/pdf/${proposal_id}/0`
    if (path) {
      window.open(path, '_blank')
    }
  }

  const handleMasterMappingDelete = (
    selectedPerson,
    resumeIndex,
    operation = null
  ) => {
    const { resumes = [], id: masterId, name } = selectedPerson
    const selectedResumeData = resumes[resumeIndex]
    const { id = '' } = selectedResumeData || {}
    console.log('selectedResumeData', selectedPerson)
    showConfirmDialog({
      onConfirm: () => {
        setIsSaving(true)
        const toastId = toast.loading(
          operation === 'remove' ? 'Moving Resume...' : 'Deleting Resume...'
        )
        const newResumes = resumes.filter(
          (resume, index) => index !== resumeIndex
        )
        const req = {
          group_id: masterId,
          people_id: id,
          op_type: operation
        }
        const callback = () => {
          toast.dismiss(toastId)
          setIsSaving(false)
          if (selectedPerson?.resumes?.length === 1) {
            setSelectedPerson(null)
            handleBacktoSearch()
          } else {
            fetchResumes(true)
          }
        }
        if (operation === 'remove') {
          trackEvent(
            mixpanelEvents.PEOPLE_RESUME_REMOVED,
            'IN-PROCESS',
            {},
            { people_name: selectedPerson?.name }
          )
        } else {
          trackEvent(
            mixpanelEvents.PEOPLE_RESUME_DELETED,
            'IN-PROCESS',
            {},
            { people_name: selectedPerson?.name }
          )
        }
        dispatch(deletePeopleMapping(req, callback))
      },
      confirmationMessageTitle: (
        <>
          Are you sure you want to {operation === 'remove' ? 'move' : operation}{' '}
          this resume ?
        </>
      )
    })
  }

  const handleMove = (fromPeople, toPeople, resumeId, faceId, onSuccess) => {
    const body = {
      from_master_id: fromPeople,
      to_master_id: toPeople,
      people_id: resumeId,
      face_id: faceId
    }

    console.log('body move', body)

    const callback = () => {
      // toast.success('Successfully moved resume')
      fetchResumes()
      onSuccess()
      refresh()
    }

    dispatch(moveResume(body, callback))
  }

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }

  const handleMasterDeleteOptionChange = (event) => {
    setSelectedMasterDeleteValue(event.target.value)
  }

  const handleMasterDelete = () => {
    setIsSaving(true)
    setSelectedPerson(null)
    setSelectedResume(null)
    handleCloseDeleteDialog()
    const id = toast.loading('Please wait...')
    const selectedPeopleData = people[selectedPerson] || {}
    const { id: masterId } = selectedPeopleData
    const callback = () => {
      setIsSaving(false)
      toast.dismiss(id)
      navigate(`${LIBRARY_PEOPLE_RESUME}`)
      if (isIndividualResumeView) {
        handleBacktoSearch()
      }
    }
    trackEvent(
      mixpanelEvents.PEOPLE_RESUME_DELETED,
      'IN-PROCESS',
      {},
      { people_name: selectedPeopleData?.name }
    )
    const deleteType =
      selectedMasterDeleteValue === 'option1' ? 'delete_all' : 'delete_mapping'
    handleCloseDeleteDialog()
    dispatch(deletePeople(masterId, deleteType, callback))
  }

  const handleVerify = (isMaster, verified) => {
    setIsSaving(true)
    const selectedPeopleData = people[selectedPerson] || {}
    const { resumes = [], id: masterId } = selectedPeopleData
    let req = {}
    const toastId = toast.loading('Please wait...')
    if (isMaster) {
      req = {
        id: masterId,
        type: 'master',
        masterId,
        verified,
        updateObj: {
          verified
        }
      }
    } else {
      const selectedResumeData = resumes[selectedResume]
      const { id } = selectedResumeData || {}
      req = {
        id,
        type: 'people',
        masterId,
        verified,
        updateObj: {
          verified
        }
      }
    }
    const callback = () => {
      setIsSaving(false)
      toast.dismiss(toastId)
    }
    dispatch(updatePeople(req, callback))
  }

  const handleMasterResumeNameUpdate = () => {
    const id = toast.loading('Changing name...')
    const data = {
      id: editingNameId,
      employee_name: editedName
    }
    const callback = () => {
      setEditedName('')
      toast.dismiss(id)
    }
    trackEvent(
      mixpanelEvents.PEOPLE_MASTER_RESUME_NAME_UPDATED,
      'Success',
      {},
      { people_name: editedName }
    )
    dispatch(updatePeopleInfo(data, callback))
  }

  const displayPeople = _.isArray(people) ? people : []
  let count = 0
  // const displayMode =
  //   selectedPerson !== null && selectedResume === null
  //     ? 'master'
  //     : selectedPerson !== null && selectedResume !== null
  //     ? 'resume'
  //     : 'none'

  if (resumeLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%'
        }}
      >
        <Loader loading={resumeLoading} caption="Loading Resumes" flex={true} />
      </div>
    )
  }

  if (showResumeEditor) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          overflow: 'hidden'
        }}
      >
        {editMode ? (
          <div
            className="grid place-content-center"
            style={{
              width: '50%',
              // height: '100%',

              backgroundColor: 'var(--grey-800)',
              overflow: 'hidden'
            }}
          >
            <div>
              <ImageGallery
                display="block"
                inline={true}
                images={selectedPerson?.resumes?.map((resume) => {
                  return { src: resume.thumbnail_url }
                })}
                onClose={() => setSelectedScreenshots([])}
              />
            </div>
          </div>
        ) : (
          <Box
            sx={{
              width: '180px',
              minWidth: '180px',
              flex: 1,
              borderRight: '1px solid #e4e4e7'
            }}
          >
            {/* {!isIndividualResumeView && pageType === 'people' && ( */}
            {pageType !== 'result' && (
              <Box
                onClick={() => handleBacktoSearch()}
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  // color: '#0645AD',
                  padding: '10px 14px',
                  paddingBottom: '10px',
                  cursor: 'pointer',
                  borderBottom: '1px solid #e4e4e7',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <ArrowBackIosIcon
                  sx={{
                    width: '16px',
                    height: '16px'
                  }}
                />
                <p
                  style={{
                    margin: '0',
                    fontSize: '14px'
                  }}
                >
                  Resumes
                </p>
              </Box>
            )}
            {/* )} */}
            <Box
              id="resume_list_parent"
              sx={{
                height: 'calc(100% - 126px)',
                overflowY: 'auto'
              }}
            >
              {editNotes ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end'
                  }}
                >
                  <TextareaAutosize
                    minRows={10}
                    style={{
                      border: 'none',
                      fontFamily: 'inherit',
                      padding: '5px',
                      maxWidth: '94%',
                      borderRadius: '6px',
                      outline: 'none'
                    }}
                    onChange={(e) => {
                      setEditedNote(e.target.value)
                    }}
                    placeholder="Type your notes here..."
                    value={editedNote}
                  />
                </div>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: '0px',
                    padding: '14px',
                    justifyContent: 'center'
                  }}
                >
                  {selectedPerson?.resumes?.map((resume, index) => {
                    const {
                      proposal_id,
                      thumbnail_url = '',
                      screenshots = [],
                      page_number = 1,
                      proposal_name = '',
                      version = ''
                    } = resume
                    const coverImage = _.isEmpty(screenshots)
                      ? thumbnail_url
                      : screenshots[0]?.src
                    const imagePayload = _.isEmpty(screenshots)
                      ? thumbnail_url
                      : screenshots
                    const displayName = proposal_name || proposal_id
                    return (
                      <>
                        <Box
                          sx={{
                            padding: '5px ',
                            border:
                              selectedResume === index
                                ? 'solid 1.5px'
                                : 'solid 1.5px #e4e4e7',
                            borderRadius: '4px',
                            '&:hover': {
                              translateY: '-2px'
                            },
                            height: '120px'
                          }}
                          id={'resume_' + index}
                          key={'resume_' + index}
                          onClick={() => handleOpenResume(index)}
                        >
                          <Box
                            sx={{
                              justifyContent: 'center',
                              height: '100%',
                              maxheight: '240px',
                              overflow: 'hidden',
                              // border: '1px solid #E5E5E5',
                              display: 'flex',
                              position: 'relative',
                              cursor: 'pointer',
                              '& .overlayButtons': {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                width: '100%',
                                borderRadius: '5px',
                                transition: 'opacity 0.3s ease'
                              },
                              '& .overlayText': {
                                position: 'absolute',
                                top: '10%',
                                left: '20%',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                                color: '#fff',
                                padding: '5px 10px',
                                borderRadius: '5px',
                                opacity: 0,
                                transition: 'opacity 0.3s ease'
                              },
                              '&:hover img': {
                                opacity: '0.5'
                              },
                              '&:hover .overlayButtons': {
                                opacity: '1 !important'
                              },
                              '&:hover .overlayText': {
                                opacity: 1
                              },
                              '&:hover::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 1
                              }
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                '& .lazy-load-image-background': {
                                  height: '100%'
                                }
                              }}
                            >
                              <ImageRender
                                style={{
                                  height: '100%',
                                  width: '100%',
                                  objectFit: 'contain',
                                  overflow: 'hidden'
                                }}
                                showLoader={false}
                                src={coverImage}
                                s3Obj={s3Obj}
                                effect="blur"
                              />
                            </Box>
                            <Box
                              className="overlayButtons"
                              sx={{
                                zIndex: 2
                              }}
                            >
                              <Tooltip title="Open screenshot" arrow>
                                <IconButton
                                  onClick={() => openImage(imagePayload)}
                                >
                                  <ImageIcon
                                    sx={{
                                      height: '18px',
                                      width: '18px',
                                      color: '#D3D3D3'
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Open proposal" arrow>
                                <IconButton
                                  onClick={() =>
                                    openPdf(proposal_id, page_number)
                                  }
                                >
                                  <OpenInNewRoundedIcon
                                    sx={{
                                      height: '18px',
                                      width: '18px',
                                      color: '#D3D3D3'
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                        </Box>
                        <Tooltip title={displayName} arrow>
                          <Box
                            sx={{
                              marginBottom: '8px',
                              cursor: 'pointer'
                            }}
                            onClick={() => openPdf(proposal_id, page_number)}
                          >
                            <TextLineLimiter
                              content={displayName}
                              limit={1}
                              wordBreak={'break-word'}
                              showMore={false}
                            />
                          </Box>
                        </Tooltip>
                      </>
                    )
                  })}
                </Box>
              )}
              <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="options"
                        name="options"
                        value={selectedMasterDeleteValue}
                        onChange={handleMasterDeleteOptionChange}
                      >
                        <FormControlLabel
                          value="option1"
                          control={<Radio />}
                          label="Delete master resume along with all individual resumes"
                        />
                        <FormControlLabel
                          value="option2"
                          control={<Radio />}
                          label="Delete only master resume and keep all individual resumes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                  <Button
                    onClick={() => {
                      handleMasterDelete()
                    }}
                    autoFocus
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
            {pageType !== 'result' && (
              <>
                <div
                  style={{
                    padding: '10px',
                    borderTop: '1px solid #e4e4e7',
                    // backgroundColor: '#e4e4e7',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '14px',
                    gap: '5px',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setEditNotes(!editNotes)
                    trackEvent(
                      mixpanelEvents.PEOPLE_RESUME_NOTES_EDITED,
                      'SUCCESS',
                      {},
                      { people_name: selectedPerson?.name }
                    )
                  }}
                >
                  {updatingNotes ? (
                    <CircleLoader className="animate-spin size-4 text-grey-500" />
                  ) : (
                    <EditNote
                      sx={{
                        height: '16px',
                        width: '16px'
                      }}
                    />
                  )}
                  Notes
                </div>
                <div
                  style={{
                    padding: '10px',
                    borderTop: '1px solid #e4e4e7',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '14px',
                    gap: '5px',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}
                >
                  <DisplayTags
                    tags={selectedPerson?.tags}
                    setShowTagDrawer={() => {
                      setShowTagDrawer(true)
                      trackEvent(
                        mixpanelEvents.PEOPLE_RESUME_TAGS_VIEWED,
                        'SUCCESS',
                        {},
                        { people_name: selectedPerson?.name }
                      )
                    }}
                  />
                </div>
              </>
            )}{' '}
          </Box>
        )}

        {selectedScreenshots.length > 0 && (
          <ImageGallery
            images={selectedScreenshots}
            onClose={() => setSelectedScreenshots([])}
          />
        )}

        {renderTagDrawer()}
        <Suspense fallback={<Loader loading={true} />}>
          {selectedPerson && (
            <Box
              sx={{
                width: '100%'
              }}
              key={
                displayMode === 'resume' ? 'resume' : selectedPerson + '_resume'
              }
              // sx={{ display: displayMode === 'resume' ? 'block' : 'none' }}
            >
              <ResumeViewer
                selectedResumeData={selectedPerson?.resumes[selectedResume]}
                selectedResumeIndex={selectedResume}
                openImage={openImage}
                s3Obj={s3Obj}
                pageType={pageType}
                selectedPeopleData={selectedPerson}
                handleMasterMappingDelete={handleMasterMappingDelete}
                handleMove={handleMove}
                setShowTagDrawer={setShowTagDrawer}
                tagState={tagState}
                handlePDFViewer={openPdf}
                key={selectedResume}
                downloadImage={handleDownloadCustomPdf}
                editMode={editMode}
                setEditMode={(val) => {
                  setEditMode(val)
                }}
              />

              {ConfirmDialog}
            </Box>
          )}
        </Suspense>
      </div>
    )
  }

  if (displayMode === 'resume' || pageType === 'result') {
    return (
      <>
        <ResumeGrid
          s3Obj={s3Obj}
          people={selectedPerson}
          isSuperUser={isSuperUser}
          handleCloseProject={() => {
            navigate(`${LIBRARY_PEOPLE_RESUME}`)
          }}
          showResumeEditor={() => setShowResumeEditor(true)}
          openImage={openImage}
          openPdf={openPdf}
          handleDownloadCustomPdf={handleDownloadCustomPdf}
          handleMasterMappingDelete={handleMasterMappingDelete}
          handleMove={(resume) => {
            setSelectedResumeOption(resume)
            setShowMoveModal(true)
          }}
        />

        {ConfirmDialog}
        <MoveModal
          open={showMoveModal}
          setOpen={setShowMoveModal}
          s3Obj={s3Obj}
          selectedPerson={selectedPerson}
          personName={selectedPerson?.name}
          onMoveToPeople={() => {
            handleMasterMappingDelete(selectedPerson, selectedResume, 'remove')
            setShowMoveModal(false)
          }}
          onMove={(destinationPeople) => {
            setMoving(true)
            handleMove(
              selectedPerson.id,
              destinationPeople,
              selectedResumeOption.id,
              selectedResumeOption.face_id,
              () => {
                setShowMoveModal(false)
              }
            )
          }}
        />

        {selectedScreenshots.length > 0 && (
          <ImageGallery
            images={selectedScreenshots}
            onClose={() => setSelectedScreenshots([])}
          />
        )}
      </>
    )
  }

  if (pageType === 'dashboard' && displayPeople.length > 0) {
    return (
      <Box>
        <ReactResizeDetector handleWidth>
          {({ width }) => {
            const itemWidth = 150 + 50
            const calculatedItemCount = Math.floor(width / itemWidth)
            const peopleList = showAllPeople
              ? people
              : people?.slice(0, calculatedItemCount)

            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  overflow: 'hidden',
                  flexWrap: 'wrap'
                }}
              >
                <>
                  {peopleList.map((person, index) => {
                    count = index
                    const {
                      name,
                      profile_picture_location,
                      resumes = []
                    } = person
                    return (
                      <Tooltip
                        arrow
                        title={name}
                        key={index}
                        placement="bottom"
                      >
                        <Box
                          sx={{
                            flexDirection: 'row',
                            display: 'flex',
                            flex: '0 0 150px',
                            padding: '8px',
                            paddingLeft: '10px',
                            width: '250px',
                            alignItems: 'center',
                            border: 'solid 1px var(--grey-300)',
                            borderRadius: '10px',
                            cursor: 'pointer',
                            paddingRight: '0'
                          }}
                          onClick={() => handleOpenPerson(index)}
                        >
                          <ImageAvatarFallback
                            name={name}
                            profilePic={profile_picture_location}
                            style={{ height: '30px', width: '30px' }}
                            openImage={() => {}}
                            s3Obj={s3Obj}
                          />
                          <Box
                            sx={{
                              margin: '0px 10px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center'
                            }}
                          >
                            <Box
                              sx={{
                                fontWeight: '600',
                                fontSize: '13px',
                                color: 'var(--grey-700)',
                                textOverflow: 'ellipsis',
                                width: '100px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              {name}
                            </Box>
                            {/* <Box
                              sx={{
                                fontSize: '12px',
                                fontWeight: '400',
                                marginTop: '5px',
                                color: '#797979'
                              }}
                            >
                              {resumes.length}
                              {resumes.length === 1
                                ? '1 Resume'
                                : resumes.length > 1
                                ? `${resumes.length} Resumes`
                                : ''}
                            </Box> */}
                          </Box>
                        </Box>
                      </Tooltip>
                    )
                  })}
                  {!showAllPeopleDefault &&
                    !(visibleItemCount >= people.length) && (
                      <Box
                        style={{
                          height: '50px',
                          display: 'contents',
                          fontSize: '12px'
                        }}
                      >
                        <Button2 secondary onClick={() => viewAllPeople()}>
                          + {people.length - visibleItemCount} More
                        </Button2>
                      </Box>
                    )}
                </>
              </Box>
            )
          }}
        </ReactResizeDetector>
      </Box>
    )
  }
}

export default ResumeResult
