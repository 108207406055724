import { makeStyles } from '@mui/styles'
import { useColor } from '../../ThemeContext'

const useStyles = makeStyles((theme) => {
  const { selectedColor } = useColor() // Get the selected color from the context

  return {
    field: {
      margin: '10px 0px 0px 0px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      /* '& div':{
        maxWidth:"170px"
      }, */
      '& button': {
        padding: '6px 14px !important'
        /* maxWidth:"170px" */
      }
      /* padding:"0 20px" */
      /* '& >div >div':{
        width:'100%'
      } */
    },
    searchButton: {
      color: '#797979',
      background: '#fff',
      margin: '10px 0px',
      '& button': {
        padding: '8.5px 14px !important'
      }
    },
    resultWrapper: {
      flexGrow: 1
    },
    label: {
      fontFamily: 'PoppinsRegular',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '16px',
      marginBottom: '-8px'
    },
    loaderContainer: {
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%'
    },
    icon: {
      float: 'left'
    },
    keyValueContainer: {
      marginTop: '5px',
      width: '100%'
    },
    keyValue: {
      width: '100%',
      padding: '4px 14px',
      backgroundColor: '#FFF',
      borderRadius: '4px',
      border: 'solid 1px #E5E5E5',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: 1.36,
      alignItems: 'center',
      background: '#FFF',
      boxSizing: 'border-box',
      color: '#515151',
      cursor: 'pointer',
      display: 'inline-flex',
      marginRight: '5px',
      flexWrap: 'nowrap',
      outline: 'currentcolor none medium !important',
      textAlign: 'left',
      textDecoration: 'none',
      transition:
        'background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s',
      verticalAlign: 'middle',
      whiteSpace: 'nowrap',
      textTransform: 'none'
    },
    keyValueTextInput: {
      '& input': {
        padding: '0px 5px'
      }
    },
    inputContainer: {
      padding: '10px 20px'
    },
    checkBoxInput: {
      width: '100%'
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'end'
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    heightWrapper: {
      height: '1vh'
    },

    advacnedFilterWrapper: {
      display: 'flex',
      alignItems: 'baseline',
      width: '100%',
      margin: '20px 0px',
      flexWrap: 'wrap',
      gap: '10px'
    },
    clearFilter: {
      color: '#797979',
      cursor: 'pointer',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.01em',
      '&:hover': {
        color: '#666262'
      }
    },
    rootContainer: {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
      maxWidth: '1000px',
      width: '65%'
    },
    rootContainerFullWidth: {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
      width: 'calc(100% - 30px)',
      padding: '0px 15px'
    },
    chatRootContainer: {
      display: 'flex',
      height: '100%',
      width: '100%'
    },
    tooltip: {
      background: 'black'
    },
    newChatButton: {
      padding: '2px 24px',
      background: selectedColor,
      fontFamily: 'PoppinsRegular',
      fontWeight: '600',
      textTransform: 'none',
      borderRadius: '6px',
      '&:hover': {
        background: '#195ccb'
      }
    },
    hideOpenDrawer: {
      fontWeight: 'bold',
      marginLeft: '33px',
      padding: '18px 0'
    },
    showOpenDrawer: {
      fontWeight: 'bold'
    },
    historySessionTitle: {
      color: '#797979',
      fontFamily: 'PoppinsRegular',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '15px',
      letterSpacing: '0.5px',
      padding: '10px 0'
    },
    historyChatTitle: {
      color: '#797979',
      fontFamily: 'PoppinsRegular',
      fontSize: '15px',
      lineHeight: '19.5px',
      letterSpacing: '1%',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: '10px 5px',
      paddingLeft: '0px',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    selectedHistoryOption: {
      background: '#EEEEEE',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    newChat: {
      padding: '15px 5px'
    },
    addHighlight: {
      '&:hover': {
        background: '#f8f8f8',
        borderRadius: '5px',
        display: 'flex'
      },
      sessionAction: {
        padding: '3px !important'
      }
    },
    errorContainer: {
      padding: '10px 20px',
      textAlign: 'center',
      fontSize: '14px'
    },
    fileViwerWrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '100%'
    },
    filterContainer: {
      margin: '10px 0px',
      display: 'flex'
    },
    filterButton: {
      fontSize: '13px',
      color: '#797979',
      padding: '2px 4px',
      border: '1px solid #797979',
      borderRadius: '5px',
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',

      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      },
      '& .MuiSvgIcon-root': {
        fontSize: '16px'
      }
    },
    clearButton: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px'
    },
    tabButton: {
      display: 'flex',
      gap: '10px'
    },
    promptbtn: {
      color: '#000',
      fontSize: '12px',
      borderRadius: '15px',
      padding: '6px 10px',
      marginTop: '5px',
      cursor: 'pointer',
      position: 'relative',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'fit-content',
      maxWidth: '100%',
      '&:hover': {
        background: '#f8f8f8',
        // transform: 'scale(1.025) translateY(-3px)',
        // transition: 'transform 0.2s ease-in-out'
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        inset: '0',
        borderRadius: '15px',
        padding: '1px',
        background: 'linear-gradient(100deg, #471CA8, #FF3465)',
        WebkitMask:
          'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude'
      }
    }
    
  }
})

export { useStyles }
