import { useEffect, useState, forwardRef } from 'react'
import { useParams } from 'react-router-dom'
import Loader from '../../components/Loader'
import { initalizeS3, getSignedUrl } from '../../utils/AWS'
import _ from 'lodash'
import moment from 'moment'
import { toast } from 'react-toastify'
import PDFViewer from '../../components/PDFViewer/PDFViewer'
import IconButton from '@mui/material/IconButton'
import Box from '@material-ui/core/Box'
import { useStyles } from './styles'
import { fetchPrompts } from '../../store/Common/Actions'
import PDFSectionEditorContainer from '../../containers/PDFSectionEditor'
import {
  checkUserRoleSuperUser,
  checkUserRoleAnnotator,
  checkUserRoleViewer
} from '../../utils/User'
import { Button, Container, Section, SectionFixed } from '../../components'
import ChatBot from '../../containers/ChatBot'
import Error from '../../components/Error'
import { Resizable } from 'react-resizable'
import { useResizeDetector } from 'react-resize-detector'
import ChatIcon from '@mui/icons-material/Chat'
import InfoIcon from '@mui/icons-material/Info'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import Tooltip from '@mui/material/Tooltip'
import CloseIcon from '@mui/icons-material/Close'
import StyleIcon from '@mui/icons-material/Style'
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined'
import LocalPoliceIcon from '@mui/icons-material/LocalPolice'
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined'
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab'
import BasicDatePicker from '../../components/DatePicker'
import ContentEditable from 'react-contenteditable'
import TagCenterUpdate from '../../sections/TagCenterUpdate'
import Divider from '@mui/material/Divider'
import { getProposal, updateResource } from '../../store/api'
import { useSelector, useDispatch } from 'react-redux'
// import DownloadIcon from '@mui/icons-material/Download'
import ShareIcon from '@mui/icons-material/Share'
import DoneIcon from '@mui/icons-material/Done'
import { initalizeDownload } from '../../utils/DownloadFromS3'
import { getPDFURL } from '../../utils/PDF'
import {
  CopiedIcon,
  DownloadIcon,
  LinkIcon
} from '../../components/Icons/Icons'
import Button2 from '../../components/Button/Button2'

const PdfViewer = (props) => {
  const {
    id: propsId = '',
    pageNo: propsPageNo,
    onClose,
    onUpdateCallback = () => {},
    activeCollection = null,
    file_type: propsType = null
  } = props

  let { fileId, pageNo, openAnnotator = false } = useParams()
  fileId = propsId || fileId
  pageNo = propsPageNo || pageNo

  const [loading, setLoading] = useState(true)
  const [fileData, setFileData] = useState({})
  const [error, setError] = useState('')
  const [s3Obj, sets3Obj] = useState({})
  const [fileURL, setFileURL] = useState('')
  const [fileDownloadURL, setFileDownloadURL] = useState('')
  const isSuperUser = checkUserRoleSuperUser()
  const isAnnotator = checkUserRoleAnnotator()
  const [file_type, setFileType] = useState(propsType)
  const [showSectionEditor, setShowSectionEditor] = useState(false)
  const common = useSelector((userState) => userState?.common)
  const { prompts = [] } = common
  const auth = useSelector((userState) => userState?.authenticate)
  const domain = auth?.user?.domain
  const { domain_id } = domain || {}
  const [listWidth, setListWidth] = useState(0)
  const [isResizing, setIsResizing] = useState(false)
  const { width, height, ref } = useResizeDetector()
  const [sidePane, setSidePane] = useState('')
  const dispatch = useDispatch()
  const classes = useStyles()
  const isUserViewer = checkUserRoleViewer()
  const [currentPageNumber, setCurrentPageNumber] = useState(pageNo)
  const [shareLinkCopied, setShareLinkCopied] = useState(false)

  useEffect(() => {
    if ((isSuperUser || isAnnotator) && openAnnotator) {
      setShowSectionEditor(true)
    }
  }, [openAnnotator])

  useEffect(() => {
    if (!prompts) {
      dispatch(fetchPrompts())
    }
  }, [prompts])

  useEffect(() => {
    setFileType(propsType)
  }, [propsType])

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    initalizeData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      if (fileId && !_.isEmpty(s3Obj)) {
        const res = await getProposal(fileId)
        if (res.status === 200) {
          const { data, file_type } = res.data
          if (!propsType) {
            setFileType(file_type)
          }
          setFileData(data)
          let url = await getPDFURL(s3Obj, data)
          let org_url = await getPDFURL(s3Obj, data, true)
          let error = false
          url = await getSignedUrl(url, s3Obj).catch(() => {
            setError('pdf not found')
            error = true
          })
          org_url = await getSignedUrl(org_url, s3Obj).catch((error) => {
            console.log(error)
          })
          setFileURL(url)
          setFileDownloadURL(org_url)
          if (!error) {
            setLoading(false)
          }
        } else {
          toast.error(`Oops! ${file_type} not found`)
          onClose()
        }
      }
    }
    setLoading(true)
    fetchData()
  }, [fileId, s3Obj])

  useEffect(() => {
    if (width > 900) {
      handleOnResize()
    } else {
      setListWidth(0)
    }
  }, [width])

  const handleOnResize = (event, obj) => {
    if (obj) {
      if (obj.size && obj.size.width < width - 500 && obj.size.width > 350) {
        setListWidth(obj.size.width)
      }
    } else {
      setListWidth(350)
    }
  }

  const CustomResizeHandle = forwardRef((props, ref2) => {
    const { handleAxis, ...restProps } = props
    return (
      sidePane && (
        <Box
          className={classes.customResizeHandler}
          style={{ background: isResizing ? '#cdcdcd' : 'inherit' }}
          ref={ref2}
          {...restProps}
        >
          <span
            className={`react-resizable-handle react-resizable-handle-${handleAxis}`}
          ></span>
        </Box>
      )
    )
  })

  const toggleSidePane = (pane) => {
    if (pane === sidePane) {
      setSidePane('')
    } else {
      setSidePane(pane)
    }
  }

  const handleEdit = async (feild, value) => {
    const req = {
      id: fileId,
      [feild]: value
    }
    if (feild === 'name' || feild === 'notes') {
      onUpdateCallback(fileId, 'loading', true)
    }
    const res = await updateResource({
      document_id: fileId,
      [feild]: value,
      type: 'document'
    })
    if (res.status === 200) {
      if (feild === 'name') {
        onUpdateCallback(fileId, 'file_name', value)
      }
      if (feild === 'notes') {
        onUpdateCallback(fileId, 'notes', value)
      }
    } else {
      toast.error('Something went wrong while updating ' + file_type)
    }
    if (feild === 'name' || feild === 'notes') {
      onUpdateCallback(fileId, 'loading', false)
    }
  }

  const handleOnChange = (value, key) => {
    setFileData({
      ...fileData,
      [key]: value
    })
  }

  const { name } = fileData || {}
  const editableFeilds = ['name', 'notes', 'last_modified_date']
  const readOnlyFeilds = ['created_by_user', 'created_at']

  const renderSidepane = () => {
    switch (sidePane) {
      case 'chat':
        return (
          <ChatBot
            trackerId={fileId}
            chatType={file_type}
            textBoxPlaceholder={'Ask some questions about this ' + file_type}
          />
        )

      case 'tags':
        return (
          <Box className={classes.sidepaneWrapper}>
            <TagCenterUpdate
              id={fileId}
              filterTags="document"
              document_type={file_type}
              onUpdateCallback={onUpdateCallback}
            />
          </Box>
        )

      case 'info':
        return (
          <Box className={classes.sidepaneWrapper}>
            <Box>
              {editableFeilds.map((feild, index) => {
                const value = fileData[feild] || ''

                if (feild === 'last_modified_date') {
                  return (
                    <Box className={classes.textContainer} key={index}>
                      <p className="m-0 text-xs text-grey-600">
                        {' '}
                        {_.startCase(feild)}
                      </p>
                      {isUserViewer ? (
                        <Box className={classes.readOnlyContent}>
                          {value
                            ? moment(new Date()).format('MM/DD/YYYY')
                            : moment(new Date(value)).format('MM/DD/YYYY')}
                        </Box>
                      ) : (
                        <BasicDatePicker
                          selectedDate={value ? new Date() : value}
                          handleDateChange={(value) => handleEdit(feild, value)}
                        />
                      )}
                    </Box>
                  )
                } else {
                  return (
                    <Box className={classes.textContainer} key={index}>
                      <p className="m-0 text-xs text-grey-600">
                        {' '}
                        {_.startCase(feild)}
                      </p>
                      <ContentEditable
                        disabled={isUserViewer}
                        html={value}
                        onChange={(e) =>
                          handleOnChange(e.currentTarget.innerText, feild)
                        }
                        onBlur={(e) =>
                          handleEdit(feild, e.currentTarget.innerText)
                        }
                        placeholder={_.startCase(feild)}
                        className="text-sm"
                        style={{
                          overflow: 'auto',
                          border: '1px solid #e5e5e5',
                          padding: '3px 6px',
                          borderRadius: '5px',
                          height: feild === 'notes' ? '150px' : 'inherit'
                        }}
                      />
                    </Box>
                  )
                }
              })}
              {readOnlyFeilds.map((feild, index) => {
                const value = fileData[feild] || ''
                if (feild === 'created_at') {
                  return (
                    <Box className={classes.textContainer} key={index}>
                      <p className="m-0 text-xs text-grey-600">Uploaded at </p>
                      <div className="text-sm font-medium text-grey-600">
                        {moment(value).format('ll')}
                      </div>
                    </Box>
                  )
                } else if (feild === 'created_by_user') {
                  return (
                    <Box className={classes.textContainer} key={index}>
                      <p className="m-0 text-xs text-grey-600">Uploaded by</p>
                      <div className="text-sm font-medium text-grey-600">
                        {value}
                      </div>
                    </Box>
                  )
                } else {
                  return (
                    <Box className={classes.textContainer} key={index}>
                      <p className="m-0 text-xs text-grey-600">
                        {_.startCase(feild)}
                      </p>

                      <div className="text-sm font-medium text-grey-600">
                        {value}
                      </div>
                    </Box>
                  )
                }
              })}
              {activeCollection?.serverType === 'collection' && (
                <>
                  <Divider sx={{ marginTop: '15px' }} />
                  <Box sx={{ marginTop: '15px' }}>
                    <Box className={classes.readOnlyContent}>
                      Collection Details
                    </Box>
                    <Box className={classes.textContainer}>
                      <Box className={classes.readOnlyContent}>Name</Box>
                      <div className={classes.readOnlyContent}>
                        {activeCollection?.name}
                      </div>
                    </Box>
                    <Box className={classes.textContainer}>
                      <Box className={classes.readOnlyContent}>Description</Box>
                      <ContentEditable
                        html={activeCollection?.description}
                        disabled
                        style={{
                          overflow: 'auto',
                          color: '#9b9696',
                          padding: '3px',
                          height: '150px'
                        }}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        )

      case 'superuser':
        return (
          <Box className={classes.sidepaneWrapper}>
            <Box className={classes.inputWrapper}>
              <Button variant="outlined" onClick={() => handleOpenAnnoator()}>
                {onClose ? 'Open PDF Annotator' : 'Switch to PDF Annotator'}
              </Button>
            </Box>
          </Box>
        )
    }
  }

  const handleOpenAnnoator = () => {
    if (onClose) {
      const path = `/view/pdf/${fileId}/0/${true}`
      window.open(path, '_blank')
    } else {
      setShowSectionEditor(true)
    }
  }

  const handlePdfDownload = async () => {
    initalizeDownload(fileDownloadURL, name, s3Obj)
  }

  const handlePdfShare = async () => {
    const page = currentPageNumber || 0
    const path = `${process.env.REACT_APP_REDIRECT_URL}view/pdf/${fileId}/${
      page + 1
    }`
    navigator.clipboard.writeText(path)

    setShareLinkCopied(true)
    setTimeout(() => {
      setShareLinkCopied(false)
    }, 1200)
  }

  if (loading) {
    return (
      <Loader
        loading={loading}
        flex
        caption={error || 'opening your ' + (file_type || 'file')}
      />
    )
  }

  return (
    <Container parentref={ref}>
      <Box className={classes.pdfContainer}>
        {!showSectionEditor && (
          <SectionFixed className={classes.headerContainer}>
            <Box className={classes.editableHeader}>
              <Box className="flex items-center gap-1">
                <span className="text-sm font-medium">{name}</span>
                <Button2
                  secondary
                  onClick={handlePdfDownload}
                  noOutline
                  style={{
                    padding: '6px',
                    marginLeft: '4px'
                  }}
                >
                  <Tooltip title="Download">
                    <DownloadIcon
                      className="size-4 text-grey-600"
                      strokeWidth={2}
                    />
                  </Tooltip>
                </Button2>
                <Button2
                  secondary
                  onClick={handlePdfShare}
                  noOutline
                  style={{
                    padding: '6px'
                  }}
                >
                  <Tooltip title="Link Copy">
                    {shareLinkCopied ? (
                      <CopiedIcon
                        className="size-4 text-grey-600"
                        strokeWidth={2}
                      />
                    ) : (
                      <LinkIcon
                        className="size-4 text-grey-600"
                        strokeWidth={2}
                      />
                    )}
                  </Tooltip>
                </Button2>
              </Box>
              {activeCollection?.serverType === 'collection' && (
                <Box className={classes.collectionName}>
                  {activeCollection?.name}
                </Box>
              )}
            </Box>
            {onClose && (
              <Box className={classes.closeIconWrapper}>
                <Tooltip title="Close">
                  <IconButton disableRipple onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </SectionFixed>
        )}
        {showSectionEditor && (file_type === 'proposal'  || file_type==='resume')? (
          <PDFSectionEditorContainer
            onClose={onClose}
            setShowSectionEditor={setShowSectionEditor}
            PDFUrl={fileURL}
            PDFOrgUrl={fileDownloadURL}
            PDFData={fileData}
            s3Obj={s3Obj}
          />
        ) : fileData?.status === 'pending' ? (
          <Error
            errorMessage={
              'Oops! Unable to locate the file. The file you are trying to access may still be uploading, or it may have been deleted. Please try again later'
            }
          />
        ) : (
          <Section overFlow={true}>
            <Box className={classes.containerDiv}>
              <Resizable
                width={listWidth}
                axis="x"
                onResize={handleOnResize}
                resizeHandles={['w']}
                onResizeStart={() => {
                  setIsResizing(true)
                }}
                onResizeStop={() => {
                  setIsResizing(false)
                }}
                handle={<CustomResizeHandle />}
              >
                <Box
                  sx={{
                    width: sidePane
                      ? `calc(100% - ${listWidth + 53}px)`
                      : 'calc(100% - 53px)'
                  }}
                  className={classes.mainContainer}
                >
                  <PDFViewer
                    url={fileURL}
                    pageNumber={pageNo}
                    domain_id={domain_id}
                    PDFData={fileData}
                    enableHighlightPlugins
                    enableAIPlugin
                    enableAddToNarratives
                    enableCopy
                    enablePageThumbnail
                    setCurrentPageNumber={setCurrentPageNumber}
                  />
                </Box>
              </Resizable>
              {sidePane && (
                <Box
                  className={classes.sidePaneContainer}
                  style={{
                    width: listWidth - 1 + 'px'
                  }}
                >
                  <Box sx={{ height: '100%', width: '100%' }}>
                    <Container>
                      <Section overFlow={true}>{renderSidepane()}</Section>
                    </Container>
                  </Box>
                </Box>
              )}
              <Box className={classes.sidepane}>
                <Box className={classes.sidepaneIconGroup}>
                  <Tooltip title="Chat">
                    <IconButton
                      disableRipple
                      onClick={() => toggleSidePane('chat')}
                    >
                      {sidePane === 'chat' ? (
                        <ChatIcon />
                      ) : (
                        <ChatOutlinedIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Tags">
                    <IconButton
                      disableRipple
                      onClick={() => toggleSidePane('tags')}
                    >
                      {sidePane === 'tags' ? (
                        <StyleIcon />
                      ) : (
                        <StyleOutlinedIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Info">
                    <IconButton
                      disableRipple
                      onClick={() => toggleSidePane('info')}
                    >
                      {sidePane === 'info' ? (
                        <InfoIcon />
                      ) : (
                        <InfoOutlinedIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                  {isSuperUser && (file_type === 'proposal' || file_type==='resume') && (
                    <Tooltip title="Superuser Tools">
                      <IconButton
                        disableRipple
                        onClick={() => toggleSidePane('superuser')}
                      >
                        {sidePane === 'superuser' ? (
                          <LocalPoliceIcon />
                        ) : (
                          <LocalPoliceOutlinedIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
                {sidePane && (
                  <Tooltip title="Close SidePane">
                    <IconButton disableRipple onClick={() => setSidePane('')}>
                      <KeyboardTabIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Section>
        )}
      </Box>
    </Container>
  )
}

export default PdfViewer
