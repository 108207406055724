import { useState, useEffect, Suspense, lazy } from 'react'
import { Box, IconButton } from '@mui/material'
import { useStyles } from './styles'
import CloseIcon from '@mui/icons-material/Close'
import { Container, Section, SectionFixed } from '../../components/Container'
import moment from 'moment'
import { Error, Loader } from '../../components'
import PdfViewer from '../../pages/PdfViewer'
import AssetViewer from './AssetViewer'
import LibraryViewer from './LibraryViewer'
import ProjectViewer from './ProjectViewer'
import ResumeResult from '../../sections/ResumeResult'
import Wrapper from './CloseWrapper'
import { useNavigate, useSearchParams } from 'react-router-dom'
const ResumeViewer = lazy(() => import('../ResumeResult/ResumeViewer'))

const ResourceFileView = (props) => {
  const {
    onClose = () => {},
    file = {},
    setFile = () => {},
    s3Obj = {},
    searchKey = '',
    isSuperUser = false,
    isAdmin = false,
    onDeleteCallback = () => {},
    onUpdateCallback = () => {},
    activeCollection = {},
    setImageGallery = () => {},
    appendToURL = false,
    isModelOpen = false
  } = props
  const classes = useStyles()
  const { id, other_data, file_name, file_type, created_at, created_by_user } =
    file || {}
  const navigate = useNavigate()

  useEffect(() => {
    if (appendToURL) {
      if (isModelOpen) {
        if (file) {
          const { other_data } = file || {}
          const { content_id, content_type = 'content_id' } = other_data || {}
          if (content_id) {
            const searchParams = new URLSearchParams(window.location.search)
            searchParams.set(content_type, content_id)
            navigate({ search: searchParams.toString() }, { replace: true })
          }
        }
      } else {
        const searchParams = new URLSearchParams(window.location.search)
        if (searchParams.has('content_id')) {
          searchParams.delete('content_id')
        }
        if (searchParams.has('people')) {
          searchParams.delete('people')
        }
        navigate({ search: searchParams.toString() }, { replace: true })
      }
    }
  }, [appendToURL, isModelOpen])

  return (
    <Container className={classes.mainWrapper}>
      <Section overFlow={true}>
        <Box className={classes.fileWrapper}>
          <Box className={classes.contentWrapper}>
            {file_type === 'proposal' || file_type === 'document' || file_type==='resume' ? (
              <PdfViewer
                id={id}
                file_type={file_type}
                onUpdateCallback={onUpdateCallback}
                onClose={onClose}
                activeCollection={activeCollection}
                pageNo={other_data?.page_number || 0}
              />
            ) : file_type === 'image' ||
              file_type === 'video' ||
              file_type === 'proposal image' ||
              file_type === 'asset' ? (
              <AssetViewer
                id={id}
                src={file_type}
                s3Obj={s3Obj}
                assetData={other_data}
                searchKey={searchKey}
                isSuperUser={isSuperUser}
                isAdmin={isAdmin}
                onDeleteCallback={onDeleteCallback}
                onClose={onClose}
                setFile={setFile}
                onUpdateCallback={onUpdateCallback}
              />
            ) : file_type === 'knowledge' ? (
              <LibraryViewer
                id={id}
                onClose={onClose}
                onDeleteCallback={onDeleteCallback}
                onUpdateCallback={onUpdateCallback}
                setFile={setFile}
                isSuperUser={isSuperUser}
                isAdmin={isAdmin}
              />
            ) : file_type === 'project' ? (
              <Wrapper onClose={onClose} file_name={file_name}>
                <ProjectViewer
                  id={id}
                  data={other_data}
                  onClose={onClose}
                  onDeleteCallback={onDeleteCallback}
                  onUpdateCallback={onUpdateCallback}
                  setFile={setFile}
                  isSuperUser={isSuperUser}
                  isAdmin={isAdmin}
                  s3Obj={s3Obj}
                />
              </Wrapper>
            ) : file_type === 'people' ? (
              <>
                {!other_data?.resumes[0] ? (
                  <Wrapper onClose={onClose} file_name={''}>
                    <Error
                      errorMessage={'Resume not found...'}
                      refresh={false}
                      errorLogo={false}
                    />
                  </Wrapper>
                ) : (
                  <Wrapper
                    onClose={onClose}
                    file_name={file_name}
                    childStyle={{
                      padding: '0px',
                      overflow: 'hidden',
                      height: 'calc(100% - 40px)',
                      width: 'calc(100% - 40px)'
                    }}
                  >
                    <Suspense fallback={<Loader />}>
                      <ResumeViewer
                        id={id}
                        s3Obj={s3Obj}
                        currentMasterPeopleIndex={0}
                        selectedResumeData={other_data?.resumes[0]}
                        onClose={onClose}
                        pageType="dashboard"
                        setSelectedScreenshots={setImageGallery}
                      />
                    </Suspense>
                  </Wrapper>
                )}
              </>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Section>
    </Container>
  )
}

export default ResourceFileView
