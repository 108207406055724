import { ROUTES } from '../../config/routes'
import { ReactComponent as Library } from '../../assets/images/Library.svg'
import { ReactComponent as Folder } from '../../assets/images/Folder.svg'
import { ReactComponent as Settings } from '../../assets/images/settings.svg'
import { ReactComponent as Home } from '../../assets/images/home.svg'
import {
  AnalyticsIcon,
  BookIcon,
  BucketIcon,
  ChatIcon,
  FolderIcon,
  HomeIcon,
  SettingsIcon,
  WorkspaceIcons
} from '../Icons/Icons'

const {
  LIBRARY_KNOWLEDGE,
  SETTINGS_PROFILE,
  SETTINGS_DOMAIN,
  SETTINGS_USERS,
  SETTINGS_CHAT,
  SETTINGS_TAGCENTER,
  SETTINGS_CHANGEDOMAIN,
  SETTINGS_CREATEDOMAIN,
  SETTINGS_IDENTITYPROVIDERS,
  SETTINGS_STYLEGUIDE,
  WORKSPACE,
  ANALYTICS_HOME_ANALYTICS,
  DASHBOARD,
  FILES
} = ROUTES
const menuItems = [
  {
    name: 'Dashboard',
    icon: (
      <HomeIcon
        style={{
          width: '18px',
          height: '18px'
        }}
      />
    ),
    route: DASHBOARD,
    toolTip: 'Home'
  },
  {
    name: 'Files',
    icon: (
      <FolderIcon
        style={{
          width: '18px',
          height: '18px'
        }}
      />
    ),
    route: FILES,
    toolTip: 'Files'
  },
  {
    name: 'Library',
    icon: (
      <BookIcon
        style={{
          width: '18px',
          height: '18px'
        }}
      />
    ),
    id: '3',
    route: LIBRARY_KNOWLEDGE,
    toolTip: 'Library',
    submenu: [
      {
        name: 'Knowledge',
        route: LIBRARY_KNOWLEDGE,
        toolTip: 'Knowledge'
      }
    ]
  },
  {
    name: 'Workspace',
    icon: (
      <WorkspaceIcons
        style={{
          width: '18px',
          height: '18px'
        }}
      />
    ),
    route: WORKSPACE,
    toolTip: 'Workspaces'
  },
  {
    name: 'Analytics',
    icon: (
      <AnalyticsIcon
        style={{
          width: '18px',
          height: '18px'
        }}
      />
    ),
    route: ANALYTICS_HOME_ANALYTICS,
    toolTip: 'Analytics'
  },
  {
    name: 'Chat',
    icon: (
      <ChatIcon
        style={{
          width: '18px',
          height: '18px'
        }}
      />
    ),
    route: 'chat/new',
    toolTip: 'Chats'
  },
  {
    name: 'Settings',
    icon: (
      <SettingsIcon
        style={{
          width: '18px',
          height: '18px'
        }}
      />
    ),
    route: SETTINGS_PROFILE,
    submenu: [],
    toolTip: 'Setting'
  }
]

const menuAnnotator = [
  {
    name: 'Dashboard',
    icon: <Home />,
    route: DASHBOARD,
    toolTip: 'Dashboard'
  },
  {
    name: 'Files',
    icon: <Folder />,
    route: FILES,
    toolTip: 'Files'
  },
  {
    name: 'Library',
    icon: <Library />,
    route: LIBRARY_KNOWLEDGE,
    toolTip: 'Library'
  },
  {
    name: 'Settings',
    icon: <Settings />,
    route: SETTINGS_PROFILE,
    toolTip: 'Setting'
  }
]

const superUserTab = [
  {
    label: 'Profile Details',
    index: 0,
    route: SETTINGS_PROFILE
  },
  {
    label: 'Domain Settings',
    index: 1,
    route: SETTINGS_DOMAIN
  },
  {
    label: 'Users',
    index: 2,
    route: SETTINGS_USERS
  },
  {
    label: 'Chat Settings',
    index: 3,
    route: SETTINGS_CHAT
  },
  {
    label: 'Tag Center',
    index: 4,
    route: SETTINGS_TAGCENTER
  },
  {
    label: 'Change Domain',
    index: 5,
    route: SETTINGS_CHANGEDOMAIN
  },
  {
    label: 'Create New Domain',
    index: 6,
    route: SETTINGS_CREATEDOMAIN
  },
  {
    label: 'Create Identity Providers',
    index: 7,
    route: SETTINGS_IDENTITYPROVIDERS
  },
  {
    label: 'Style Guide',
    index: 8,
    route: SETTINGS_STYLEGUIDE
  }
]

const annotatorTab = [
  {
    label: 'Profile Details',
    index: 0,
    route: SETTINGS_PROFILE
  },
  {
    label: 'Change Domain',
    index: 1,
    route: SETTINGS_CHANGEDOMAIN
  }
]

const adminTab = [
  {
    label: 'Profile',
    name: 'Profile',
    index: 0,
    route: SETTINGS_PROFILE
  },
  {
    label: 'Domain Settings',
    name: 'Domain Settings',
    index: 1,
    route: SETTINGS_DOMAIN
  },
  {
    label: 'Users',
    index: 2,
    route: SETTINGS_USERS
  },
  {
    label: 'Chat Settings',
    index: 3,
    route: SETTINGS_CHAT
  },
  {
    label: 'Tag Center',
    index: 4,
    route: SETTINGS_TAGCENTER
  },
  {
    label: 'Style Guide',
    index: 5,
    route: SETTINGS_STYLEGUIDE
  }
]

const userTab = [
  {
    label: 'Profile Details',
    index: 0,
    route: SETTINGS_PROFILE
  }
]

export {
  menuItems,
  menuAnnotator,
  superUserTab,
  annotatorTab,
  adminTab,
  userTab
}
